<template>
    <div class="chart">
        <div class="container">

            <div class="chart_wrapper">
                <div class="chart__chart-box chart-container">
                    <LineChartGenerator
                        :chart-options="chartOptions"
                        :chart-data="chartData"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                        :width="width"
                        :height="height"
                    />
                </div>
                <div class="chart__chart-box chart-container">
                    <LineChartGenerator
                        :chart-options="chartOptions"
                        :chart-data="chartData2"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                        :width="width"
                        :height="height"
                    />
                </div>
                <div class="chart__chart-box chart-container">
                    <LineChartGenerator
                        :chart-options="chartOptions"
                        :chart-data="chartData3"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                        :width="width"
                        :height="height"
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)
const token = localStorage.getItem('user');

export default {
    data() {
        return {
            statistics: [],
            statisticsClients: [],
            chartData: {
                labels: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
                datasets: [
                    {
                        label: 'Przychody 2021',
                        data: [], 
                        borderColor: 'blue',
                        fill: false,
                    },
                    {
                        label: 'Przychody 2022',
                        data: [],
                        borderColor: 'red',
                        fill: false,
                    },
                    {
                        label: 'Przychody 2023',
                        data: [], 
                        borderColor: 'green',
                        fill: false,
                    },
                ]
            },
            chartData2: {
                labels: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
                datasets: [
                    {
                        label: 'Klienci 2021',
                        data: [], 
                        borderColor: 'blue',
                        fill: false,
                    },
                    {
                        label: 'Klienci 2022',
                        data: [], 
                        borderColor: 'red',
                        fill: false,
                    },
                    {
                        label: 'Klienci 2023',
                        data: [], 
                        borderColor: 'green',
                        fill: false,
                    },
                ]
            },
            chartData3: {
                labels: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
                datasets: [
                    {
                        label: 'Koszty 2021',
                        data: [], 
                        borderColor: 'blue',
                        fill: false,
                    },
                    {
                        label: 'Koszty 2022',
                        data: [], 
                        borderColor: 'red',
                        fill: false,
                    },
                    {
                        label: 'Koszty 2023',
                        data: [], 
                        borderColor: 'green',
                        fill: false,
                    },
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                        ticks: {
                            beginAtZero: true,
                            suggestedMin: 0, 
                        },
                        },
                    ],
                },
            }
        }
    },
    components:{
        LineChartGenerator
    },
    props: {
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getTotalValues(data) {
            let result = {};
            // let res2 = [];

            for (const year in data) {
                result[year] = [];
                for (const month of data[year].months) {
                result[year][month.month - 1] = (result[year][month.month - 1] || 0) + month.total;
                }
            }

            return result;
        },
        extractYears(data) {
            const years = {};

            data.forEach(item => {
                const year = item._id.year;
                if (!years[year]) {
                    years[year] = {
                        months: []
                    };
                }

                years[year].months.push({
                    month: item._id.month,
                    total: item.total
                });
            });

            return this.getTotalValues(years);
        },
        async getAllStatistics() {
            try {
                const resp = await this.$axios.get(`${this.url}api/statistics`, { headers: { 'x-access-token': token } });
                this.statistics = this.extractYears(resp.data);
                this.chartData.datasets[0].data = this.statistics["2021"];
                this.chartData.datasets[1].data = this.statistics["2022"];
                this.chartData.datasets[2].data = this.statistics["2023"];
            } catch (error) {
                console.error(error);
            }

            try {
                const resp2 = await this.$axios.get(`${this.url}api/statistics/clients`, { headers: { 'x-access-token': token } });
                this.statisticsClients = this.extractYears(resp2.data);
                this.chartData2.datasets[0].data = this.statisticsClients["2021"];
                this.chartData2.datasets[1].data = this.statisticsClients["2022"];
                this.chartData2.datasets[2].data = this.statisticsClients["2023"];
            } catch (error) {
                console.error(error);
            }

            try {
                const resp3 = await this.$axios.get(`${this.url}api/statistics/copycosts`, { headers: { 'x-access-token': token } });
                this.statisticsCopy = this.extractYears(resp3.data);
                console.log(resp3.data);
                this.chartData3.datasets[0].data = this.statisticsCopy["2021"];
                this.chartData3.datasets[1].data = this.statisticsCopy["2022"];
                this.chartData3.datasets[2].data = this.statisticsCopy["2023"];
            } catch (error) {
                console.error(error);
            }
        },
    },
    mounted() {
        this.getAllStatistics();
    },
};
</script>

<style scoped lang="scss">
.chart{
    &__wrapper{
        display: flex;
    }
    &__chart-box{
        flex: 1;
    }
}

#chart5{
    width: 50%;
}
</style>
