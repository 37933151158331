<template>
	<div>
		<Statistics />
	</div>
</template>


<script>
import Statistics from '@/components/molecules/Statistics.vue'
export default {
	components: {
		Statistics
	}
}
</script>