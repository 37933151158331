<template>
    <div>
        <h1>Statystyki</h1>

        <!-- <pre>{{ statistics }}</pre> -->

        <Chart/>

        <!-- 2. Podłączenie pod wykres -->
    </div>
</template>

<script>

import Chart from '@/components/molecules/Chart.vue';

export default {
    components: {
        Chart
    },
    data() {
        return {
            statistics: [],   
        };
    },
    computed: {

    },
};
</script>

<style scoped lang="scss">
</style>
